<template>
    <div class="card p-fluid">
        <h5><b>{{$t('accountinfo.view.header')}}</b></h5>
        <div class="p-field p-grid">
            <label for="name3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('accountinfo.view.name')}}:</label>
            <div class="p-col-12 p-md-10">{{ name }}</div>
        </div>
        <div class="p-field p-grid">
            <label for="email3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('accountinfo.view.email')}}:</label>
            <div class="p-col-12 p-md-10">{{ email }}</div>
        </div>
        <div class="p-field p-grid">
            <label for="name3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('accountinfo.view.phone')}}:</label>
            <div class="p-col-12 p-md-10">{{ mobile === null ? '-' : mobile}}</div>
        </div>
        <div class="p-d-flex p-jc-between">
            <Button :label="$t('accountinfo.view.edit')" class="p-button-outlined p-mr-2 p-mb-2" @click="editinfo"></Button>
            <!-- <Button label="Primary" class="p-button-outlined p-mr-2 p-mb-2" @click="$router.go(-1)">뒤로가기</Button> -->
        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            name: localStorage.getItem('UserName'),
            email: localStorage.getItem('UserEmail'),
            mobile: localStorage.getItem('UserMobile'),
            decodedStr: '',
        }
    },
    methods: {



        editinfo() {


            this.$router.push({ name: 'EditInfo' });
        }
    }
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}
</style>
